import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import Footer from '../component/Footer/Footer'
import ChaptersComponent from '../component/ChaptersComponent/ChaptersComponent'

const ChaptersPage = () => {
  return (
    <>
    <Navbar />
    <ChaptersComponent />
    <Footer />
    </>
  )
}

export default ChaptersPage