import React from 'react'
import FaqComponent from '../component/FaqComponent/FaqComponent'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'

const FaqPage = () => {
  return (
    <>
    <Navbar />
    <FaqComponent />
    <Footer />
    </>
  )
}

export default FaqPage