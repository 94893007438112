import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import styles from './BoardComponent.module.css';
import banner from './../../assets/images/Manchester-United.jpg';
import member from './../../assets/images/Manchester-United.jpg'
import axios from 'axios';

const boardMembers = [
  {
    name: "ANDY GREEN",
    title: "DIRECTOR OF FINANCE",
    details: "Elected in 2022. Awaiting profile text.",
    image: member, // Update with actual image path if available
  },
  {
    name: "CHRIS BAKER",
    title: "BOARD MEMBER",
    details: "Awaiting profile text.",
    image: member, // Update with actual image path if available
  },
  {
    name: "CHRIS RUMFITT",
    title: "BOARD MEMBER",
    details: "Chris has been a season ticket holder for many years...",
    image: member, // Update with actual image path if available
  },
  {
    name: "DAVE PENNINGTON",
    title: "VICE CHAIR",
    details: "Elected in 2017. Dave is a life-long United fan...",
    image: member, // Update with actual image path if available
  },
  {
    name: "DAVID KIDMAN",
    title: "VICE CHAIR",
    details: "Elected in ?. Awaiting profile text.",
    image: member, // Update with actual image path if available
  },
  {
    name: "DUNCAN DRASDO",
    title: "CEO",
    details: "Nobody has put in as many hours, worn as many hats...",
    image: member, // Update with actual image path if available
  },
  {
    name: "GERALD SHAMASH",
    title: "BOARD MEMBER, IMMEDIATE PAST CHAIR",
    details: "Elected in 2015. Born, bred and educated in Manchester...",
    image: member, // Update with actual image path if available
  },
  {
    name: "IAN STIRLING",
    title: "VICE CHAIR",
    details: "Sadly, Ian passed away on 12th March, 2023...",
    image: member, // Update with actual image path if available
  },
  {
    name: "JD DEITCH",
    title: "CHAIR",
    details: "Elected in 2021. JD joined Shareholders United in January 2004...",
    image: member, // Update with actual image path if available
  },
  {
    name: "MARK SOUTHEE",
    title: "BOARD MEMBER",
    details: "Mark joined SU/M (Shareholders United Against Murdoch)...",
    image: member, // Update with actual image path if available
  },
  {
    name: "PHIL BARTON",
    title: "SECRETARY",
    details: "I have supported Manchester United since 1963 when...",
    image: member, // Update with actual image path if available
  },
];

const BoardComponent = () => {
    const [boardMembers, setBoardMembers] = useState([])

  useEffect(() => {
    const getBoards = async () => {
      const response = await axios.get('https://api.mufcredbrigade.com/api/get-all-boards')
      setBoardMembers(response.data.trustBoard)
    }
    getBoards()
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src={banner} alt="banner" />
      </div>
      <div className={styles.boardContent}>
        <aside className={styles.sidebar}>
        <ul>
            <li>
              <NavLink to="/about-us" activeClassName={styles.activeLink}>RBSU Timeline</NavLink>
            </li>
            <li>
              <NavLink to="/committee" activeClassName={styles.activeLink}>Executive Committee</NavLink>
            </li>
            <li>
              <NavLink to="/board-executives" activeClassName={styles.activeLink}>The Trust Board</NavLink>
            </li>
            <li>
              <NavLink to="/constitution" activeClassName={styles.activeLink}>Our Constitution</NavLink>
            </li>
            <li>
              <NavLink to="/founders" activeClassName={styles.activeLink}>Founders</NavLink>
            </li>
            <li>
              <NavLink to="/faq" activeClassName={styles.activeLink}>FAQ</NavLink>
            </li>
       
           
          </ul>
        </aside>
        <main className={styles.mainContent}>
          <h2>Board Members</h2>
          {boardMembers && boardMembers.map((member, index) => (
            <div key={index} className={styles.member}>
              <div className={styles.details}>
                <h3>{member.name}</h3>
                <p className={styles.title}>{member.title}</p>
                <p>{member.location}</p>
                {/* {member.details.length > 100 && <a href="#">Read more</a>} */}
              </div>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
};

export default BoardComponent;
