import React from 'react'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'
import SingleProductComponent from '../component/SingleProductComponent/SingleProductComponent'

const SingleProduct = () => {
  return (
    <>
    <Navbar />
    <SingleProductComponent />
    <Footer />
    </>
  )
}

export default SingleProduct