import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styles from './StoreComponent.module.css';
import axios from 'axios';



const StoreComponent = () => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const response = await axios.get("https://api.mufcredbrigade.com/api/get-all-products")

        if ( response.status === 200) {
          setProducts(response.data.products)
          console.log(response.data.products)
        }
      } catch (error) {
        console.log(error.message)
      }
    }

   

    getAllProducts()
  }, [])
  
  return (
    <div className={styles.gridContainer}>
      { products.length > 0 ? products.map(product => (
        <Link to={`/store/product/${product._id}`} key={product.id} className={styles.productCard}>
        <span className={styles.saleBadge}>SALE</span>
        <img src={`https://api.mufcredbrigade.com/${product.image}`} alt={product.name} className={styles.productImage} />
        <p className={styles.productCategory}>{product.category}</p>
        <h2 className={styles.productName}>{product.name}</h2>
        <div className={styles.productPrice}>
           <span className={product.price && styles.originalPrice}>${product.originalPrice}</span>
           {product.price && <span> ${product.price }</span>}
        </div>
      </Link>
      ))
    :
    <h1>No Product Available</h1>
    } 
      
    </div>
  );
};

export default StoreComponent;