import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import FooterComponent from '../component/Footer/Footer'
import GetCardComponent from '../component/GetCardComponent/GetCardComponent'

const GetCard = () => {
  return (
    <>
    <Navbar />
    <GetCardComponent  />
    <FooterComponent />
    </>
  )
}

export default GetCard