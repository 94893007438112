import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './SingleProductComponent.module.css';

const SingleProductComponent = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://api.mufcredbrigade.com/api/get-product/${productId}`);
        setProduct(response.data.product);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  if (!product) return <div>Loading...</div>;

  const handleBuyNow = () => {
    navigate(`/store/product/checkout/${productId}`, { state: { product } });
  };

  return (
    <div className={styles.singleProductContainer}>
      <div className={styles.productImg}>
        <img src={`https://api.mufcredbrigade.com/${product.image}`} alt={product.name} className={styles.productImage} />
      </div>
      <div className={styles.productDetails}>
        <h1 className={styles.productName}>{product.name}</h1>
        <p className={styles.productCategory}>{product.category}</p>
        <div className={styles.productPrice}>
          <span className={product.price && styles.originalPrice}>${product.originalPrice}</span>
          {product.price && <span>${product.price}</span>}
        </div>
        <button onClick={handleBuyNow}>Buy Now</button>
      </div>
    </div>
  );
};

export default SingleProductComponent;