import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import Hero from '../component/Hero/Hero'
import Purpose from '../component/Purpose/Purpose'
import NewsBlogs from '../component/NewsBlog/NewsBlog'
import Donate from '../component/Donate/Donate'
import ContactComponent from '../component/ContactComponent/ContactComponent'
import FooterComponent from '../component/Footer/Footer'

const HomePage = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <Purpose />
    <NewsBlogs />
    <Donate />
    <ContactComponent />
    <FooterComponent />
    </>
  )
}

export default HomePage