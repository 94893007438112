import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SuccessComponent.module.css';

const SuccessComponent = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className={styles.successContainer}>
      <div className={styles.successContent}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase. Your order is being processed, and you will receive a confirmation email shortly.</p>
        <button onClick={handleBackToHome} className={styles.homeButton}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default SuccessComponent;