import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AboutComponent.module.css';
import banner from './../../assets/images/Manchester-United.jpg'

const AboutComponent = () => {
  return (
    <div className={styles.container}>
       <div className={styles.banner}>
            <img src={banner} alt="banner" />
        </div>
      <div className={styles.aboutContent}>
        <aside className={styles.sidebar}>
          <ul>
            <li>
              <NavLink to="/about-us" activeClassName={styles.activeLink}>RBSU Timeline</NavLink>
            </li>
            <li>
              <NavLink to="/committee" activeClassName={styles.activeLink}>Executive Committee</NavLink>
            </li>
            <li>
              <NavLink to="/board-executives" activeClassName={styles.activeLink}>The Trust Board</NavLink>
            </li>
            <li>
              <NavLink to="/constitution" activeClassName={styles.activeLink}>Our Constitution</NavLink>
            </li>
            <li>
              <NavLink to="/founders" activeClassName={styles.activeLink}>Founders</NavLink>
            </li>
            <li>
              <NavLink to="/faq" activeClassName={styles.activeLink}>FAQ</NavLink>
            </li>
       
           
          </ul>
        </aside>
        <main className={styles.mainContent}>
          <h2>History of the Red Brigade Supporters Union</h2>
          <section>
            <h3>Formation and Early Days (2017)</h3>
            <p>
            The Red Brigade Supporters Union, originally known as Forever United, was founded in September 2017 by Dr. Boateng Samuel, a passionate Manchester United fan with a vision to create a supportive community for fellow enthusiasts. Recognizing the need for a platform where fans could come together to share their love for the club, Dr. Samuel initiated the formation of a WhatsApp group. This group quickly became a vibrant space for fans to discuss matches, share opinions, and celebrate the legacy of Manchester United.
            </p>
            <p>
            Assisting Dr. Samuel was Mr. Joseph Albert Barnett from Sierra Leone, whose enthusiasm and commitment helped lay the groundwork for the organization. Together, they fostered a sense of camaraderie and belonging among members, making it an inclusive environment for all fans regardless of geographical location.
            </p>
            
          </section>
          <section>
            <h3>Growth and Development (2018-2022)</h3>
            <p>
            As word spread about Forever United, the group saw substantial growth, attracting members from various backgrounds and countries. The discussions evolved from match analysis and player performance to sharing news. The initial WhatsApp group expanded into several social media platforms, further amplifying its reach and engagement.

            </p>
            <p>
            The atmosphere was always buoyant, celebrating not only victories but also supporting each other during tough times for the team.

            </p>
            
          </section>
          <section>
            <h3>Rebranding to Red Brigade Supporters Union (2023)</h3>
            <p>
            In 2023, as the organization grew in size and ambition, a decision was made to rebrand from Forever United to the Red Brigade Supporters Union. This new name reflected the unity and strength of the member community, symbolizing a brigade of loyal supporters dedicated to Manchester United. The rebranding also included a fresh logo and a more structured approach to the union’s activities.
            </p>
            <p>
            The Red Brigade has also established connections with other Manchester United supporter clubs worldwide, fostering a global network of fans.

            </p>
          </section>
          <section>
            <h3>Conclusion</h3>
            <p>
            From its humble beginnings as a WhatsApp group to becoming a recognized supporters union, the Red Brigade Supporters Union has grown into a vibrant community of Manchester United fans. With Dr. Boateng Samuel at the helm and the support of dedicated members like Mr. Joseph Albert Barnett, the organization continues to bring fans together, celebrate their shared passion for the club, and make an impactful difference in their communities. As they look to the future, the Red Brigade remains committed to supporting Manchester United and fostering the spirit of unity and friendship among its members.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default AboutComponent;
