import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import FoundersComponent from '../component/FoundersComponent/FoundersComponent'
import Footer from './../component/Footer/Footer'

const FoundersPage = () => {
  return (
    <>
    <Navbar />
    <FoundersComponent />
    <Footer />
    </>
  )
}

export default FoundersPage