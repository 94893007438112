import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import styles from './ConstitutionComponent.module.css';
import banner from './../../assets/images/Manchester-United.jpg'

const ConstitutionComponent = () => {
  return (
    <div className={styles.container}>
       <div className={styles.banner}>
            <img src={banner} alt="banner" />
        </div>
      <div className={styles.constitutionContent}>
        <aside className={styles.sidebar}>
        <ul>
            <li>
              <NavLink to="/about-us" activeClassName={styles.activeLink}>RBSU Timeline</NavLink>
            </li>
            <li>
              <NavLink to="/committee" activeClassName={styles.activeLink}>Executive Committee</NavLink>
            </li>
            <li>
              <NavLink to="/board-executives" activeClassName={styles.activeLink}>The Trust Board</NavLink>
            </li>
            <li>
              <NavLink to="/constitution" activeClassName={styles.activeLink}>Our Constitution</NavLink>
            </li>
            <li>
              <NavLink to="/founders" activeClassName={styles.activeLink}>Founders</NavLink>
            </li>
            <li>
              <NavLink to="/faq" activeClassName={styles.activeLink}>FAQ</NavLink>
            </li>
       
           
          </ul>
        </aside>
        <main className={styles.mainContent}>
          <p>The Rules of RBSU can be downloaded <Link to="/">here</Link> </p>
        </main>
      </div>
    </div>
  );
};

export default ConstitutionComponent;
