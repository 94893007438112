import React from 'react'
import CommitteeComponent from '../component/CommitteeComponent/CommitteeComponent'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'

const CommitteePage = () => {
  return (
    <>
    <Navbar />
    <CommitteeComponent />
    <Footer />
    </>
  )
}

export default CommitteePage