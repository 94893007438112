import React from 'react';
import styles from './BlogNewsComponent.module.css';
import { Link } from 'react-router-dom';

const BlogNewsComponent = () => {
  return (
    <div className={styles.container}>
      <h2>RBSU statement on European Super League and ownership</h2>
      <p>21/04/2021</p>
      <p>
        We did it! What a 48 hours that was…. Well, first things first, we did it. We’ve stopped the breakaway. The Super League is dead. And based on the overwhelming reaction from fans, the media, pundits, players and managers, it’s hard to imagine anyone being stupid enough to bring such an idea back again.
      </p>
      <p>
        And what a fight we did. No, we don’t mean RBSU alone although we played our part. We mean we the fans. The reaction from supporters across football, whether from other clubs who were destined for this competition, or from fans whose teams were nothing to do with it, the reaction was so united and so overwhelming that no-one could ignore us. Politicians wouldn’t have intervened without the backlash from fans. Players and managers would not have found it as easy to speak without the backlash from fans. Broadcasters and sponsors speaking out did so with more ease because of the passion shown by fans.
      </p>
      <p>
        That said, we at RBSU can be very proud of the part we ourselves have played. In the last 48 hours we’ve been in meetings with both Boris Johnson and Keir Starmer, and made the case for why they needed to get involved. We’ve done countless media interviews. And we’ve been working with other fans groups to issue statements and plan protests for the future. Some of these won’t see the light of day now, but we can be proud of the work we put in.
      </p>
      <p>
        So what next? The main thing is, we need to make this a turning point – both for United and for football as a whole. We have shown the power fans have, and we have to build on the amazing energy and momentum the short but noisy campaign against the Super League has had.
      </p>
      <p>
        The government has announced that despite the collapse of the Super League plans, they are going to go ahead with the fan-led review of football they announced yesterday. This is important. We have spent over fifteen years saying the law needs to be changed to give supporters more power over their football clubs, and now we have a government review looking at just that. Engaging in this review process is going to be a top priority for RBSU in the coming few months. We simply cannot rest on our laurels, pat ourselves on the back and wait for another similar plan to emerge down the line.
      </p>
      <p>
        Turning to our club, all of us were shocked and appalled – but not surprised – that it appears we were very much amongst the ring-leaders of this insidious endeavour. For sixteen years now, we have been talking about the negative consequences of our debt-ridden ownership structure and the owners who inflicted it upon us. In many ways, the vile Super League plan was the crystallisation of everything we have been opposing and fighting against.
      </p>
      <p>
        This must be a turning point for football, and it must be a turning point for United too. Ed Woodward is now leaving the club, but our beef was never with Ed personally. It is not about any one Club employee personally. Many of them are good people placed in a really difficult position. No, the problems at Manchester United are at ownership level. With Ed going, and their Super League dream in tatters, maybe the Glazers ought to consider if now is their moment to leave the pitch too.
      </p>
      <p>
        But we don’t want them selling off to the highest bidder and fans to just be stood on the sidelines waiting to find out who takes over. This is a real opportunity for the Glazers to now change the current path of their legacy and open the door to supporter shareholding with full voting rights. That can be put in place now to allow supporters time to start building a stake.
      </p>
      <p>
        A last comment on protest as there are numerous suggestions circulating on social media although generally anonymously. We have our strategy for how we believe we can best progress our objectives but we respect others may take a different view on tactics from time to time and naturally some fans will want to protest against the Glazers ownership which we all wish to see change. While we are not organising any such protest we do fully support the right of our fans to express their views in any protest conducted in a lawful and safe way.
      </p>
      <p>
        Thanks to each and every RBSU member and volunteer who has helped out in the last few days. It’s been quite a whirlwind. Now we have shown what fan power can do we must build on this success. And with your ongoing support, we will.
      </p>
      <p>
        If you are not already a member please join us today <Link to="/join-us" >Join Us</Link> 
      </p>
    </div>
  );
};

export default BlogNewsComponent;
