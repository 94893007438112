import React from 'react'
import LottieAnimation from '../component/LottieAnimation'
import Navbar from '../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'
import StoreComponent from '../component/StoreComponent/StoreComponent'

const StorePage = () => {
  return (
    <>
    <Navbar />
    {/* <LottieAnimation /> */}
    <StoreComponent />
    <Footer />
    </>
  )
}

export default StorePage