import React from 'react'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'
import FlipCard from '../component/FlipCard/FlipCard'

const CardPage = () => {
  return (
    <>
    <Navbar />
    <FlipCard />
    <Footer />
    </>
  )
}

export default CardPage