import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import BoardComponent from '../component/BoardComponent/BoardComponent'
import Footer from './../component/Footer/Footer'

const ExecutivesPage = () => {
  return (
    <>
    <Navbar />
    <BoardComponent />
    <Footer />
    </>
  )
}

export default ExecutivesPage