import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import HomePage from './pages/HomePage';
import NewsBlog from './pages/NewsBlog';
import AboutPage from './pages/AboutPage';
import FoundersPage from './pages/FoundersPage'
import ExecutivesPage from './pages/ExecutivesPage'
import FaqPage from './pages/FaqPage'
import ConstitutionPage from './pages/ConstitutionPage'
import CommitteePage from './pages/CommitteePage';
import ContactPage from './pages/ContactPage';
import ChaptersPage from './pages/ChaptersPage';
import JoinPage from './pages/JoinPage';
import LoginPage from './pages/LoginPage';
import SingleChapterPage from './pages/SingleChapterPage';
import StorePage from './pages/StorePage';
import CardPage from './pages/CardPage';
import SingleProduct from './pages/SingleProduct';
import GetCard from './pages/GetCard'
import CheckOutPage from './pages/CheckOutPage';
import SuccessPage from './pages/SuccessPage';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' exact element={ <HomePage /> } />
        <Route path='/about-us' element={ <AboutPage /> } />
        <Route path='/blog/news' element={ <NewsBlog /> } />
        <Route path='/board-executives' element={ <ExecutivesPage /> } />
        <Route path='/founders' element={ <FoundersPage /> } />
        <Route path='/faq' element={ <FaqPage /> } />
        <Route path='/constitution' element={ <ConstitutionPage /> } />
        <Route path='/committee' element={ <CommitteePage /> } />
        <Route path='/contact' element={ <ContactPage /> } />
        <Route path='/chapters' element={ <ChaptersPage /> } />
        <Route path='/chapters/:chapterId' element={ <SingleChapterPage /> } />
        <Route path='/join-us' element={ <JoinPage /> } />
        <Route path='/login' element={ <LoginPage /> } />
        <Route path='/card' element={ <CardPage /> } />
        <Route path='/get-card' element={ <GetCard /> } />
        <Route path='/store' element={ <StorePage /> } />
        <Route path='/store/product/:productId' element={ <SingleProduct /> } />
        <Route path='/store/product/checkout/:productId' element={ <CheckOutPage /> } />
        <Route path='/success' element={ <SuccessPage /> } />
      </Routes>
    </Router>
    </>
  );
}

export default App;
