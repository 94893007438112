import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import FooterComponent from '../component/Footer/Footer'
import JoinComponent from '../component/JoinComponent/JoinComponent'

const JoinPage = () => {
  return (
    <>
    <Navbar />
    <JoinComponent />
    <FooterComponent />
    </>
  )
}

export default JoinPage