import React from 'react'
import ConstitutionComponent from '../component/ConstitutionComponent/ConstitutionComponent'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'

const ConstitutionPage = () => {
  return (
    <>
    <Navbar />
    <ConstitutionComponent />
    <Footer />
    </>
  )
}

export default ConstitutionPage