import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './Hero.module.css';
import heroProfile from './../../assets/images/redbrigade.png'
import heroProfile1 from './../../assets/images/Manchester-United.jpg'
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper/modules';


const Hero = () => {
    const navigate = useNavigate()
    return (
        <div className={styles.hero}>
            <div className={styles.left}>
                <Swiper
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: true,
                    }}
                    modules={[Autoplay]}
                    className={styles.swiper}
                >
                    <SwiperSlide><img src={heroProfile} alt="Image 1" className={styles.swiperProfile} /></SwiperSlide>
                    <SwiperSlide><img src={heroProfile1} alt="Image 2" className={styles.swiperProfile} /></SwiperSlide>
                    <SwiperSlide><img src={heroProfile} alt="Image 3" className={styles.swiperProfile} /></SwiperSlide>
                </Swiper>
            </div>
            <div className={styles.right}>
                <h2 className={styles.brandTitle}>Members Login</h2>
                <form className={styles.form}>
                    <input type="text" placeholder="Membership #" className={styles.inputField} />
                    <input type="password" placeholder="Password" className={styles.inputField} />
                    <div className={styles.actions}>
                        <a href="#" className={styles.forgotLink}>Forgotten your password?</a>
                        <button type="submit" className={styles.loginButton}>Login</button>
                    </div>
                </form>
                <div className={styles.joinSection}>
                    <p className={styles.joinText}>Want to become a member?</p>
                    <button onClick={()=> {navigate("/join-us")}} className={styles.joinButton}>Join</button>
                </div>
            </div>
        </div>
    );
};

export default Hero;