import React from 'react';
import ReactCountryFlag from "react-country-flag";
import styles from './ChaptersComponent.module.css';
import { useNavigate } from 'react-router-dom';

const chapters = [
  { name: 'Ghana', countryCode: 'GH' },
  { name: 'Nigeria', countryCode: 'NG' },
  { name: 'Sierra Leone', countryCode: 'SL' },
  { name: 'Zambia', countryCode: 'ZM' },
];

const ChaptersComponent = () => {

  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      {chapters.map((chapter, index) => (
        <div onClick={() => { navigate(`/chapters/${chapter.name}`) } } key={index} className={styles.card}>
          <ReactCountryFlag
            countryCode={chapter.countryCode}
            svg
            className={styles.flagIcon}
          />
          <h3>{chapter.name}</h3>
        </div>
      ))}
    </div>
  );
}

export default ChaptersComponent;