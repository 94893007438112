import React from 'react'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'
import BlogNewsComponent from '../component/BlogNewsComponent/BlogNewsComponent'

const NewsBlog = () => {
  return (
    <>
    <Navbar/>
    <BlogNewsComponent />
    <Footer />
    </>
  )
}

export default NewsBlog