import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './Purpose.module.css';
import { useNavigate } from 'react-router-dom';

const Purpose = () => {
  const [activeSection, setActiveSection] = useState(null);

  const navigate = useNavigate()

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className={styles.purpose}>
      <div className={styles.section}>
        <div
          className={styles.sectionHeader}
          onClick={() => toggleSection('purpose')}
        >
          Our Purpose
           {activeSection === 'purpose' ? <FaChevronUp /> : <FaChevronDown />} 
        </div>
        <div
          className={`${styles.sectionContent} ${activeSection === 'purpose' ? styles.show : ''}`}
        >
          <p>To:</p>
          <ul>
            <li>Represent MUFC fans</li>
            <li>Support our team</li>
            <li>Defend our club</li>
          </ul>
          <p>
            Our mission is to represent the interests of United fans whose continued passionate support is pivotal to the success of our team and to preserve the heritage of our club for future generations. Through our collective community, fans can have the meaningful influence at club and national level that they desire and deserve.
          </p>
        </div>
      </div>

      <div className={styles.section}>
        <div
          className={styles.sectionHeader}
          onClick={() => toggleSection('aims')}
        >
          Our Aims
          {activeSection === 'aims' ? <FaChevronUp /> : <FaChevronDown />} 
        </div>
        <div
          className={`${styles.sectionContent} ${activeSection === 'aims' ? styles.show : ''}`}
        >
          <h3>Support and deliver in the interests of our match-going fans</h3>
          <ul>
            <li>Engage regularly with our match-going fanbase</li>
            <li>Continue to support constructive dialogue with senior club executives</li>
            <li>Work collaboratively with National Supporter bodies</li>
            <li>Lead on regional to national campaigns on supporter interests</li>
            <li>Engage with national football authorities</li>
            <li>Assist our supporters with match-related needs</li>
            <li>Provide advice and support to members who experience match-going issues</li>
          </ul>
          <h3>Support and bring the United fans community together locally, nationally, and internationally</h3>
          <ul>
            <li>Provide regular news, information, and advice</li>
            <li>Link and collaborate with United fanbases</li>
            <li>Organise and facilitate events and meetings</li>
            <li>Promote and support projects in the local community</li>
          </ul>
          <h3>Offer every fan the chance to own a stake in the club</h3>
          <ul>
            <li>Develop the opportunity for United supporters to acquire a collective shareholding</li>
          </ul>
        </div>
      </div>

      <div className={styles.section}>
        <div
          className={styles.sectionHeader}
          onClick={() => toggleSection('achievements')}
        >
          Our Achievements
          {activeSection === 'achievements' ? <FaChevronUp /> : <FaChevronDown />} 
        </div>
        <div
          className={`${styles.sectionContent} ${activeSection === 'achievements' ? styles.show : ''}`}
        >
          <ul>
            <li>Reduction of 25% on ticket prices for home League Cup and Europa League matches</li>
            <li>A progressive increase in youth season ticket discounts</li>
            <li>Removal of administrative charges for group tickets</li>
            <li>More youth tickets made available in more areas of the stadium</li>
            <li>Moderating club sanctions on supporter behaviour</li>
            <li>Secure club backing for the implementation of Safe Standing</li>
            <li>Negotiating changes in the process of allocating away tickets</li>
            <li>Working with club to improve away allocations</li>
            <li>Assisting in dialogue with the club on opportunities to modernise the stadium</li>
            <li>Setting up the match day Foodbank partnership</li>
            <li>Lobbying the Premier League to consider the future introduction of safe standing sections</li>
            <li>Introduction of a mandatory process of Structured Dialogue between club executives and supporter groups</li>
          </ul>
        </div>
      </div>

      <div className={styles.section}>
        <div
          className={styles.sectionHeader}
          onClick={() => toggleSection('priorities')}
        >
          Our Current Priorities
          {activeSection === 'priorities' ? <FaChevronUp /> : <FaChevronDown />} 
        </div>
        <div
          className={`${styles.sectionContent} ${activeSection === 'priorities' ? styles.show : ''}`}
        >
          <ul>
            <li>Establish and develop the new dedicated RBSU Supporter Liaison Officer</li>
            <li>Launch and support the new Match Going Membership initiative</li>
            <li>Establish a community screening and events programme</li>
            <li>Consult with our away match-going supporters on the case for further review of away ticket allocation policy</li>
            <li>Engage with the club, other fan groups, and supporters to identify opportunities to address match atmosphere</li>
            <li>Further develop our website based on user feedback</li>
            <li>Consult with increased new membership base on any key emerging issues</li>
            <li>Develop our charitable activity to support the local community and other causes</li>
          </ul>
        </div>
      </div>

      <div className={styles.membership}>
        <div className={styles.membershipHeader}>
          Membership
        </div>
        <div className={styles.membershipContent}>
          <ul>
            <li>Dedicated support service provided by our own RBSU Supporter Liaison Officer</li>
            <li>Consulted regularly on issues of importance to match-going fans</li>
            <li>Represented collectively on club and premier league level supporter interests</li>
            <li>Priority access to meetings, match screenings, and other social events</li>
            <li>Email newsletter</li>
            <li>Access to auctions, competitions, and free prize draws</li>
            <li>More features and services to be developed</li>
            <li>Free membership - reclaim your membership fee as shop credit when you spend $50 in the RBSU shop</li>
            <li>Vote on how Trust is run and policy developed</li>
            <li>Postal or digital newsletter</li>
            <li>Opportunity to stand for election to committee and board</li>
            <li>Collectors' membership certificate and badge</li>
          </ul>
          <div >$15</div>
          <a href="/join-us" className={styles.joinButton}>Join</a>
          <div>or join as a Lite member for free to show your support.</div>
        </div>
      </div>
    </div>
  );
};

export default Purpose;