import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import FooterComponent from '../component/Footer/Footer'
import AboutComponent from '../component/AboutComponent/AboutComponent'

const AboutPage = () => {
  return (
    <>
    <Navbar />
    <AboutComponent />
    <FooterComponent />
    </>
  )
}

export default AboutPage