import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import styles from './CommitteeComponent.module.css';
import banner from './../../assets/images/Manchester-United.jpg';
import axios from 'axios';

const CommitteeComponent = () => {
  const [committeeMembers, setCommitteeMembers] = useState([])

  useEffect(() => {
    try {
      const getCommittee = async () => {
        const response = await axios.get('https://api.mufcredbrigade.com/api/get-all-committee')
        if( response.status === 200) {
          setCommitteeMembers(response.data.committees)
        }
        
      }

      getCommittee()
    } catch (error) {
      console.log(error)
    }

  }, [])
  
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src={banner} alt="banner" />
      </div>
      <div className={styles.committeeContent}>
        <aside className={styles.sidebar}>
        <ul>
            <li>
              <NavLink to="/about-us" activeClassName={styles.activeLink}>RBSU Timeline</NavLink>
            </li>
            <li>
              <NavLink to="/committee" activeClassName={styles.activeLink}>Executive Committee</NavLink>
            </li>
            <li>
              <NavLink to="/board-executives" activeClassName={styles.activeLink}>The Trust Board</NavLink>
            </li>
            <li>
              <NavLink to="/constitution" activeClassName={styles.activeLink}>Our Constitution</NavLink>
            </li>
            <li>
              <NavLink to="/founders" activeClassName={styles.activeLink}>Founders</NavLink>
            </li>
            <li>
              <NavLink to="/faq" activeClassName={styles.activeLink}>FAQ</NavLink>
            </li>
       
           
          </ul>
        </aside>
        <main className={styles.mainContent}>
          <h2>Committee Members</h2>
          {committeeMembers.map((member, index) => (
            <div key={index} className={styles.member}>
              <div className={styles.details}>
                <h3>{member.name}</h3>
                <h4 className={styles.title}>{member.title}</h4>
                <p> {member.location} </p>
              </div>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
};

export default CommitteeComponent;
