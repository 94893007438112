import React from 'react'
import CheckOutComponent from '../component/CheckOutComponent/CheckOutComponent'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'

const CheckOutPage = () => {
  return (
    <>
    <Navbar />
    <CheckOutComponent />
    <Footer />
    </>
  )
}

export default CheckOutPage
