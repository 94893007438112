import React from 'react';
import styles from './ContactComponent.module.css';
import { useNavigate } from 'react-router-dom';

const ContactComponent = () => {
  const phoneNumbers = [
    { number: '+15045135113', whatsapp: '15045135113' },
    { number: '+233244167205', whatsapp: '233244167205' },
    { number: '+905057515027', whatsapp: '905057515027' },
    { number: '+2348104561073', whatsapp: '2348104561073' },
  ];

  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h2>Contact</h2>
        <p>
          If you would like to volunteer or get in touch please contact us by telephone on the numbers below or complete the contact form below. We look forward to reading your message.
        </p>
        <div className={styles.contacts}>
          {phoneNumbers.map((phone, index) => (
            <a
              key={index}
              href={`https://wa.me/${phone.whatsapp}`}
              className={styles.phoneLink}
            >
              {phone.number}
            </a>
          ))}
        </div>
        <form>
          <div className={styles.inputGroup}>
            <label>Name*</label>
            <input type="text" name="name" required />
          </div>
          <div className={styles.inputGroup}>
            <label>Membership # (if applicable)</label>
            <input type="text" name="membership" />
          </div>
          <div className={styles.inputGroup}>
            <label>Email*</label>
            <input type="email" name="email" required />
          </div>
          <div className={styles.inputGroup}>
            <label>Mobile number</label>
            <input type="tel" name="mobile" />
          </div>
          <div className={styles.inputGroup}>
            <label>Nature of contact</label>
            <select name="nature">
              <option value="">Select</option>
              <option value="volunteer">Match going related issue</option>
              <option value="inquiry">Club related issue (non-M.U.S.T member)</option>
              <option value="feedback">I am a member of the media or have a press enquiry</option>
              <option value="feedback">I forgot my RBSU member number or password</option>
              <option value="feedback">I have a comment or suggestion for RBSU</option>
              <option value="feedback">I have a general question about RBSU</option>
              <option value="feedback">I have a question about the RBSU shop</option>
              <option value="feedback">I'm a PAID member and have questions about my account</option>
              <option value="feedback">Other</option>
            </select>
          </div>
          <div className={styles.inputGroup}>
            <label>Your message*</label>
            <textarea name="message" required></textarea>
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
      <div className={styles.infoContainer}>
        <h3>RBSU HQ</h3>
        <address>
          18 Sir Matt Busby Way<br />
          Old Trafford<br />
          Manchester<br />
          M16 0QG
        </address>
        {/* <div className={styles.images}>
          <img src="image1.jpg" alt="image1" />
          <img src="image2.jpg" alt="image2" />
          <img src="image3.jpg" alt="image3" />
          <img src="image4.jpg" alt="image4" />
          <img src="image5.jpg" alt="image5" />
          <img src="image6.jpg" alt="image6" />
        </div> */}
        <div className={styles.map}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.7531851814406!2d-1.9950207262774498!3d5.301159286162323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdd6e03f0030d63%3A0x9fb3953236a34d1f!2sTNA%20Stadium!5e0!3m2!1sen!2sgh!4v1725891479033!5m2!1sen!2sgh"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;