import React from 'react'
import Navbar from './../component/Navbar/Navbar'
import Footer from './../component/Footer/Footer'
import SingleChapterComponent from '../component/SingleChapterComponent/SingleChapterComponent'

const SingleChapterPage = () => {
  return (
    <>
    <Navbar />
    <SingleChapterComponent />
    <Footer />
    </>
  )
}

export default SingleChapterPage