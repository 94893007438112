import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './FaqComponent.module.css';
import banner from './../../assets/images/Manchester-United.jpg'

const FaqComponent = () => {
    return (
        <div className={styles.container}>
             <div className={styles.banner}>
            <img src={banner} alt="banner" />
        </div>
            <div className={styles.faqContent}>
                <aside className={styles.sidebar}>
                <ul>
            <li>
              <NavLink to="/about-us" activeClassName={styles.activeLink}>RBSU Timeline</NavLink>
            </li>
            <li>
              <NavLink to="/committee" activeClassName={styles.activeLink}>Executive Committee</NavLink>
            </li>
            <li>
              <NavLink to="/board-executives" activeClassName={styles.activeLink}>The Trust Board</NavLink>
            </li>
            <li>
              <NavLink to="/constitution" activeClassName={styles.activeLink}>Our Constitution</NavLink>
            </li>
            <li>
              <NavLink to="/founders" activeClassName={styles.activeLink}>Founders</NavLink>
            </li>
            <li>
              <NavLink to="/faq" activeClassName={styles.activeLink}>FAQ</NavLink>
            </li>
       
           
          </ul>
                </aside>
                <main className={styles.mainContent}>
                    <section>
                        <h3>What are RBSU’s long term aims with regard to ownership of the Club?</h3>
                        <p>
                            We retain a long-term aim of a meaningful supporter ownership stake in the Club ideally alongside like-minded major investors to give the optimum mix - similar to the shareholder make-up of German clubs. How and when that can be achieved has to reflect current and future realities and is largely outside of our control at this time. We keep this policy under review and will consult with members as and when any such opportunity may arise.
                            In the meantime, we made a deliberate decision several years ago to re-engage with the Club to represent our members and deliver tangible benefits and improvements particularly for our match-going fans.
                        </p>
                    </section>
                    <section>
                        <h3>Why have you brought in a new category of (match-going) membership?</h3>
                        <p>
                        We have significantly and successfully ramped up our work on supporter issues in the last 3 years, focussing particularly on ticket prices, away match issues (including ballots, the club’s disciplinary policy and travel issues) and stadium atmosphere. At a national level we work with the Football Supporters Federation and follow PL Supporter Trusts to campaign for a better deal for supporters including safe standing, issues around fixture changes for TV and away ticket prices - the recent $30 away ticket cap was a major achievement, delivered by coordinated supporter group action.

These issues impact and benefit home and away match going supporters. We want to hear and respond to your needs so that we can represent you in our ongoing engagement with United and the Premier League and the FA.  

We also want to provide better support and services including match screenings, local events, match information and a specific legal advice and support service for those in trouble.

But we recognise that many supporters who would benefit do not have a real interest in supporter ownership that we have campaigned for historically. Match-Going membership therefore provides a more focussed (and affordable, at a nominal $5 per year) offer for those supporters.
                        </p>
                    </section>
                    <section>
                        <h3>Is RBSU’s new relationship with the club working?</h3>
                        <p>
                        Our formal and informal dialogue has resulted in some significant gains for match-going supporters over the last 3-4 years. We have been able to ensure that a supporter perspective is central to most key decisions and that an improved relationship with supporters is a win for both parties. The extent of our dialogue and positive outcomes are ahead of most if not all other Premier league clubs.

That said we will continue to speak out and challenge the club (and the football authorities) when it makes poor decisions that are not in the interests of supporters. There are times that we have not been consulted (such as the plans to meet new government requirements on disabled supporter capacity). We would like to see a wider dialogue on strategic and financial policy as it impacts on supporters and continue to lobby the Premier League and government to implement previously agreed commitments to formalise ‘structured dialogue’ with supporter groups. 
                        </p>
                    </section>
                    <section>
                        <h3>Does RBSU represent just its members or all United fans?</h3>
                        <p>
                        We invite fans to join as a Match-Going Member or a Full Member (that includes voting rights and access to the Withdrawable Share Capital - explained separately on this Q&A) to help to fund our operation and become involved in running the organisation.  However, RBSU seeks to represent all United fans, however they choose to support the club and whatever their views, and to deliver in their collective and individual interests irrespective of their RBSU membership status. 

Our most significant day to day focus currently is on match-going fans but over the longer term we will also continue to represent and work for supporters who want to see a better ownership model which prioritises the Club and the fans ahead of the shareholders. 

United, like most football clubs, has a number of supporter groups that have different particular interests and expertise who may seek to engage directly with the club to pursue their goals. RBSU will support those groups but if we can represent a single collaborative view that voice will be stronger and more likely to be listened to and delivered. 
                        </p>
                        
                    </section>
                    <section>
                        <h3>Is your focus just on United supporters – what about national campaigns?</h3>
                        <p>
                        We also recognise that many supporter issues are common to the supporters of all football clubs, and progressing those is most effective when we work together. So we are very active in the national supporter group networks that are coordinated by the Football Supporters Federation and Supporters Direct where collectively we focus on major campaigns including reduced away ticket prices (that has resulted in a $30 cap that will save any (fortunate) United supporter who attends every PL away match over $250 per season from previous prices (that were over $60 at Arsenal & Chelsea). 

The influence of RBSU and our leading involvement in national campaigns is widely acknowledged. We have taken a major role in lobbying government to change the governance of football clubs and to introduce minimum standards of ‘structured dialogue’ with supporter groups and the campaign to bring back standing sections in our grounds. A RBSU Board Member is also an elected member of the FSF National Council.  We also engage with Football Supporters Europe to campaign and lobby on common issues relating to our participation in UEFA competitions
                        </p>
                        
                    </section>
                    <section>
                        <h3>Is your focus just on United supporters – what about national campaigns?</h3>
                        <p>
                        We also recognise that many supporter issues are common to the supporters of all football clubs, and progressing those is most effective when we work together. So we are very active in the national supporter group networks that are coordinated by the Football Supporters Federation and Supporters Direct where collectively we focus on major campaigns including reduced away ticket prices (that has resulted in a $30 cap that will save any (fortunate) United supporter who attends every PL away match over $250 per season from previous prices (that were over $60 at Arsenal & Chelsea). 

The influence of RBSU and our leading involvement in national campaigns is widely acknowledged. We have taken a major role in lobbying government to change the governance of football clubs and to introduce minimum standards of ‘structured dialogue’ with supporter groups and the campaign to bring back standing sections in our grounds. A RBSU Board Member is also an elected member of the FSF National Council.  We also engage with Football Supporters Europe to campaign and lobby on common issues relating to our participation in UEFA competitions
                        </p>
                        
                    </section>
                    <section>
                        <h3>What is ‘Withdrawable Share Capital’?</h3>
                        <p>
                        Members’ Withdrawable Share Capital was created using the proceeds of the compulsory purchase of members’ shares (at the time of the Glazer takeover) which had been held in the Shareholders United Share Scheme. When the shares were sold members were offered the option to receive a cheque payment or to retain their funds as WSC within RBSU. WSC serves the same purpose as in the days of SU, to be the seed for shareholder ownership but also provides a limited opportunity for the organisation to generate revenue through prudent investment and, indeed, pay interest to members. Regrettably the current share structure that the Glazers have set up (whereby their shares hold 10 times the voting rights of the smaller number of shares that they made publicly available on the New York Stock Exchange) provides no immediate prospect of establishing our aspirations of a meaningful stake in the Club. However, it certainly remains an item on the agenda on the occasions we meet with Ed Woodward. Perhaps surprisingly, the total WSC has actually increased year on year since 2005 as many members increase their investment monthly or occasionally. The total members WSC currently stands at approx $2.5 million. However, as indicated in the name, members’ share capital is indeed withdrawable, simply by sending a written request to us.
                        </p>
                        
                    </section>
                    <section>
                        <h3>What is RBSU’s corporate and financial structure? </h3>
                        <p>
                        RBSU is a registered Co-operative and Community Benefit Society regulated by the Financial Conduct Authority.  Under advice from our accountants we registered and operate a separate trading company purely as a tax efficient way of accounting for our merchandising. Our operating costs are spread across the two accounts that, together, represent RBSU’s financial position. Owing to the way that costs and revenues are distributed across the two entities it is meaningless to view them individually.

Member funds are ring-fenced. The total Withdrawable Share Capital has actually grown marginally every year since its creation in 2005 as many members still contribute to their account on a regular basis. The total members WSC currently stands at approx $2.5 million. With the previous consent of our Full (Paid) Members we have invested some of those funds in property to realise additional income to assist the organisation in pursuit of its objectives, but the vast majority of funds remain on bank deposit.

RBSU funds its operations and projects from income and reserves that are separate from the WSC. Over the last couple of years the significant additional costs of upgrading our systems have resulted in a reduction of these reserves, but we have now taken steps to increase our income to reverse this with particular emphasis on merchandise which is clearly an optional purchase for members and non-members alike.

RBSU members (WSC shareholders) own the organisation. However, in the event of dissolution, the organisation's assets (excluding WSC which belongs to members) would not be distributed to members. Rather they would be donated to a similar non-profit or charitable organisation, where possible, with similar objectives and also with the same clause in their constitution stipulating that neither profits nor assets can be distributed to members/shareholders.
                        </p>
                        
                    </section>
                    <section>
                        <h3>Do we have paid employees?</h3>
                        <p>
                        We employ staff on a full time (currently two), part time or casual basis for membership and general office administration, merchandise sales, accommodation rental and project development. We have hired consultants or commissioned outsourced services for specialist work. This includes the considerable recent costs of modernising the website and back-end systems necessary to administer our membership database, the WSC and our communication channels. For many years we also hosted and supported the websites for both remaining United Fanzines for which service we made no charge. We also co-funded building, and continue to pay for hosting, of the VoteFootball.org campaign site that is used for major national campaigns.

Our Chief Executive gave up his professional career to work full time, indeed more than ‘full time’, to build up RBSU and lead it through our long period of hostile opposition. In 2012, the Board agreed to pay him a modest salary, which is still below the national average wage, plus a contribution to establish a pension in partial recognition of the substantial financial loss he had incurred during more than a decade working full time without pay. The Directors, Committee and other volunteers continue to provide many hours of their time without being paid by RBSU.  

When funds allow we would like to increase the number of paid employees to provide more resources to allow us to be more effective in our work and more ambitious with our projects to supplement the work done by volunteers. However, we will always be primarily a volunteer organisation. Our pay rates are above minimum wage but below national average wage so it is certainly passion for the cause, rather than financial incentive, that motivates anyone who chooses to work for RBSU.

Fundamentally the Trust runs on a not-for profit basis (indeed we are prohibited by our rules from distributing profits).
                        </p>
                        
                    </section>
                    <section>
                        <h3>What is the ISLO role and how does it work?</h3>
                        <p>
                        We set up our own Independent Supporter Liaison Officer (ISLO) role to provide an improved, ongoing and more direct service to match going fans. Our Vice-Chair, Ian Stirling, proposed that we set up this service and volunteered to lead it because he is passionate about the interests of match-going fans. His experience, extensive contacts with fans and within the Club, and most of all his energy and enthusiasm, make him the ideal person to lead it. He invests hours of his time every week that impacts heavily on his day job so he currently receives a limited reimbursement through a sponsor that partially, but by no means fully, compensates him.  

As ISLO Ian advises individual supporters on any issues or problems that they may have and, where necessary, takes them up directly with the appropriate Club official. Many fans who have faced unfair sanctions or had problems with season ticket renewals, ballots and other applications or just need to know how things work, have been helped. He also provides match going advice through the @ISLO_RBSU Twitter to support match-going fans, particularly for away matches domestically and in Europe. The response has been overwhelmingly positive as over 4500 followers will testify.
                        </p>
                        
                    </section>
                    <section>
                        <h3>Why does RBSU sit on the Fans Forum?</h3>
                        <p>
                        The Fans Forum is a Premier League requirement and is run by the Club. We don’t set the rules on how its members are appointed but those have improved in recent years including RBSU being invited back onto it - we were removed in 2005. The Club seek Forum approval for many supporter related decisions. That participation, alongside our own direct dialogue with senior executives, is how we deliver for all match going supporters.
                        </p>
                        
                    </section>
                    <section>
                        <h3>How do we engage with United supporters?</h3>
                        <p>
                        In the current age and with United’s supporter base living busy lives and spread far beyond Manchester, meetings are no longer an effective or appropriate way of engaging. The actions that we undertake are informed by email exchanges with supporters who have registered themselves on our database (whether or not they are a paid member), and through social media where we have over 30,000 followers. We also listen to supporters when we go to matches with them - home, away and in Europe. On key issues we will undertake appropriate forms of survey. We inform supporters about what we are doing through those same channels and on our website. We also make extensive use of the media to represent our supporters views and issues.  We don’t rule out also having some open meetings in the future if we can be confident that they would be allowed to operate without being disrupted as some have been in the past.
                        </p>
                        
                    </section>
                </main>
            </div>
        </div>
    );
};

export default FaqComponent;
