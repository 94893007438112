import React from 'react';
import styles from './NewsBlog.module.css';
import imf from './../../assets/images/Manchester-United.jpg'

const blogs = [
  {
    title: 'Letter to RBSU from Omar Berrada on the day he starts as CEO of Manchester United',
    date: '13/07/2024',
    image: imf, // replace with actual image path
  },
  {
    title: "United Fans' Survey Results on VAR (Video Assistant Referee) Technology",
    date: '31/05/2024',
    image: imf, // replace with actual image path
  },
  {
    title: 'Big Win for Fans as Club Change 17/19 Proposal',
    date: '25/04/2024',
    image: imf, // replace with actual image path
  },
  {
    title: 'RBSU Appointed to Old Trafford Regeneration Task Force',
    date: '08/03/2024',
    image: imf, // replace with actual image path
  },
];

const NewsBlogs = () => {
  return (
    <div className={styles.newsBlogs}>
      <div className={styles.header}>
        <h2>News & Blogs</h2>
        <a href="/more" className={styles.more}>more...</a>
      </div>
      <div className={styles.blogs}>
        {blogs.map((blog, index) => (
          <div key={index} className={styles.blog}>
            <img src={blog.image} alt={blog.title} className={styles.blogImage} />
            <div className={styles.blogTitle}>{blog.title}</div>
            <div className={styles.blogDate}>{blog.date}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsBlogs;
