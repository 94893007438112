import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode';
import JsBarcode from 'jsbarcode';
import styles from './GetCardComponent.module.css';
import cardFront from './../../assets/images/front.png';
import cardBack from './../../assets/images/back.png';

const GetCardComponent = () => {
  const location = useLocation();
  const formData = location.state || {}; // Default to an empty object if state is undefined
  const frontCanvasRef = useRef(null);
  const backCanvasRef = useRef(null);
  const qrCodeCanvasRef = useRef(null);
  const barcodeCanvasRef = useRef(null);

  useEffect(() => {
    if (formData && formData.fullName) {
      drawFrontCard();
      drawBackCard();
    }
  }, [formData]);


  const drawFrontCard = () => {
    const canvas = frontCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = cardFront;
    image.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.font = '16px Arial';
      ctx.fillStyle = 'black';
      ctx.fillText(`${formData.fullName}`, 10, 160);
      ctx.fillText(`${formData.manUtdMembershipID}`, 10, 180);

      // Draw QR code directly on the canvas
      QRCode.toCanvas(
        document.createElement('canvas'),
        JSON.stringify(formData),
        { width: 100, margin: 1 },
        (error, qrCanvas) => {
          if (!error) {
            ctx.drawImage(qrCanvas, 290, 110);
          } else {
            console.error('QR Code generation failed: ', error);
          }
        }
      );
    };
  };

  const drawBackCard = () => {
    const canvas = backCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = cardBack;
    image.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.font = '19px Arial';
      ctx.fillText(`${formData.fullName}`, 10, 90);

      // Generate Barcode
      const barcodeCanvas = document.createElement('canvas');
      JsBarcode(barcodeCanvas, 'https://mufcredbrigade.com', {
        format: 'CODE128',
        width: 1,
        height: 30,
      });
      ctx.drawImage(barcodeCanvas, 20, 150);
    };
  };

  return (
    <div className={styles.getCardComponent}>
      <div className={styles.card}>
        <h2>Front of the Card</h2>
        <canvas ref={frontCanvasRef} width={400} height={250}></canvas>
        <button onClick={() => {
          const canvas = frontCanvasRef.current;
          const link = document.createElement('a');
          link.download = 'front-membership-card.png';
          link.href = canvas.toDataURL();
          link.click();
        }}>
          Download Front Card
        </button>
      </div>
      <div className={styles.card}>
        <h2>Back of the Card</h2>
        <canvas ref={backCanvasRef} width={400} height={250}></canvas>
        <button onClick={() => {
          const canvas = backCanvasRef.current;
          const link = document.createElement('a');
          link.download = 'back-membership-card.png';
          link.href = canvas.toDataURL();
          link.click();
        }}>
          Download Back Card
        </button>
      </div>
    </div>
  );
};

export default GetCardComponent;