import React from 'react';
import styles from './Footer.module.css';
import { FaInstagram, FaFacebookF, FaTwitter, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterComponent = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.socialMedia}>
        <a href="https://www.instagram.com/mufcredbrigade" target="_blank" rel="noopener noreferrer" className={styles.icon}>
          <FaInstagram />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100063604905810&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className={styles.icon}>
          <FaFacebookF />
        </a>
        <a href="https://youtube.com/@redbrigademedia?si=xZNGDmzIfQPNjAu0" target="_blank" rel="noopener noreferrer" className={styles.icon}>
          <FaYoutube />
        </a>
        <a href="https://x.com/RealManUtdFans?t=ioshG_wLhj_9ACmxNhWU9g&s=09" target="_blank" rel="noopener noreferrer" className={styles.icon}>
          <FaTwitter />
        </a>
        <a href="https://whatsapp.com/channel/0029VaYfRHa6GcGMKLcLVI3C" target="_blank" rel="noopener noreferrer" className={styles.icon}>
          <FaWhatsapp />
        </a>
      </div>
      <div className={styles.links}>
        <Link to="/">Terms of use</Link> | 
        <Link to="/" >Privacy policy</Link> | 
        <Link to="/">Cookie policy</Link> | 
        <Link to="/" >Equality charter</Link>
      </div>
      <div className={styles.copyright}>
      Copyright &copy; 2024 MUFC-RBSU, The Official MUFC-Red Brigade Supporters Union. All rights reserved
      </div>
    </footer>
  );
};

export default FooterComponent;
