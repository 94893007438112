import React, { useState } from 'react';
import styles from './Donate.module.css';

const Donate = () => {
    const [donationAmount, setDonationAmount] = useState(null);
    const [otherAmount, setOtherAmount] = useState('');
    const [isOtherAmount, setIsOtherAmount] = useState(false);

    const handleDonationClick = (amount) => {
        setDonationAmount(amount);
        setIsOtherAmount(false);
        setOtherAmount('');
    };

    const handleOtherAmountClick = () => {
        setDonationAmount(null);
        setIsOtherAmount(true);
    };

    return (
        <div className={styles.donate}>
            <div className={styles.header}>
                <h2>Donate</h2>
            </div>
            <p>
                RBSU is a not-for-profit organisation and we are working towards a meaningful fan influence in the club's decision-making process. Please show your support and help maintain the organisation with any donations gratefully appreciated.
            </p>
            <div className={styles.content}>
                <div className={styles.formContainer}>

                    <form>
                        <div className={styles.inputGroup}>
                            <input type="text" placeholder="Name*" className={styles.inputField} required />
                        </div>
                        <div className={styles.inputGroup}>
                            <input type="text" placeholder="Membership # (If applicable)" className={styles.inputField} />
                        </div>
                        <button type="submit" className={styles.submitButton}>Donate</button>
                    </form>
                </div>
                <div className={styles.optionsContainer}>
                    <div className={styles.donationOptions}>
                        <span className={styles.donationLabel}>Donate</span>
                        <div className={styles.donationAmounts}>
                            {[5, 10, 20, 50, 100].map((amount) => (
                                <button
                                    type="button"
                                    key={amount}
                                    className={`${styles.donationButton} ${donationAmount === amount ? styles.active : ''}`}
                                    onClick={() => handleDonationClick(amount)}
                                >
                                    ${amount}
                                </button>
                            ))}
                        </div>
                        <div className={styles.otherAmountContainer}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isOtherAmount}
                                    onChange={handleOtherAmountClick}
                                    className={styles.otherAmountCheckbox}
                                />
                                Other Amount
                            </label>
                            {isOtherAmount && (
                                <input
                                    type="number"
                                    value={otherAmount}
                                    onChange={(e) => setOtherAmount(e.target.value)}
                                    className={styles.otherAmountInput}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Donate;