import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import countryCodesList from 'country-codes-list';
import styles from './JoinComponent.module.css';
import axios from 'axios';

const JoinComponent = () => {
  const [step, setStep] = useState(1);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    dateOfBirth: '',
    gender: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    residentialAddress: '',
    country: '',
    city: '',
    occupation: '',
    companyName: '',
    workAddress: '',
    membershipType: '',
    membershipDuration: '',
    chapter: '',
    leadershipPosition: '',
    manUtdMembershipID: '',
    heardAboutUs: '',
    reasonForJoining: '',
    agreement: false,
    privacyPolicy: false,
    signature: '',
    date: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch country codes from country-codes-list
    const countryCodesData = countryCodesList.customList('countryNameEn', '{countryNameEn} (+{countryCallingCode})');
    const countryCodesArray = Object.keys(countryCodesData).map(key => ({
      name: key,
      code: countryCodesData[key]
    }));
    setCountryCodes(countryCodesArray);

    // Fetch country names from country-codes-list
    const countriesData = countryCodesList.customList('countryNameEn', '{countryNameEn}');
    const countriesArray = Object.keys(countriesData).map(key => ({
      name: countriesData[key],
    }));
    setCountries(countriesArray);

    // Set available chapters (Example chapters, replace with your actual data)
    setChapters(['Ghana', 'Nigeria', 'Sierra Leone', 'Zambia']);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateStep = () => {
    switch (step) {
      case 1:
        return formData.fullName && formData.dateOfBirth && formData.gender && formData.email &&
               formData.phoneNumber && formData.countryCode && formData.residentialAddress &&
               formData.country && formData.city;
      case 2:
        return formData.occupation && formData.companyName;
      case 3:
        return formData.membershipType && formData.membershipDuration && formData.chapter &&
               formData.leadershipPosition && (formData.leadershipPosition === 'no' || formData.manUtdMembershipID);
      case 4:
        return formData.heardAboutUs && formData.reasonForJoining;
      case 5:
        return formData.signature && formData.date && formData.agreement && formData.privacyPolicy;
      default:
        return false;
    }
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
    } else {
      alert('Please fill all the fields in this step before proceeding.');
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      try {
        const response = await axios.post("http://api.mufcredbrigade.com/api/add-member", formData);
        if (response.status === 200) {
          navigate('/card', { state: formData });
        } else {
          alert('Submission failed, please try again.');
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('An error occurred while submitting the form.');
      }
    } else {
      alert('Please fill all the fields in this step before proceeding.');
    }
  };

  return (
    <div className={styles.joinComponent}>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className={styles.formSection}>
            <h2>Personal Information</h2>
            <div className={styles.input}>
              <label>Full Name</label>
              <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Date of Birth</label>
              <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Gender</label>
              <select name="gender" value={formData.gender} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className={styles.input}>
              <label>Email Address</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Country Code</label>
              <select name="countryCode" value={formData.countryCode} onChange={handleChange} required>
                <option value="">Select</option>
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>{`${country.name} (${country.code})`}</option>
                ))}
              </select>
            </div>
            <div className={styles.input}>
              <label>Phone Number</label>
              <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Residential Address</label>
              <input type="text" name="residentialAddress" value={formData.residentialAddress} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Country</label>
              <select name="country" value={formData.country} onChange={handleChange} required>
                <option value="">Select</option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className={styles.input}>
              <label>City or Town</label>
              <input type="text" name="city" value={formData.city} onChange={handleChange} required />
            </div>
            <button type="button" onClick={handleNextStep}>Next</button>
          </div>
        )}

        {step === 2 && (
          <div className={styles.formSection}>
            <h2>Professional Information</h2>
            <div className={styles.input}>
              <label>Occupation</label>
              <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Company/Organization Name</label>
              <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Work Address</label>
              <input type="text" name="workAddress" value={formData.workAddress} onChange={handleChange} />
            </div>
            <div className={styles.joinBtns}>
              <button type="button" onClick={handlePreviousStep}>Previous</button>
              <button type="button" onClick={handleNextStep}>Next</button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className={styles.formSection}>
            <h2>Membership Details</h2>
            <div className={styles.input}>
              <label>Type of Membership</label>
              <select name="membershipType" value={formData.membershipType} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="full">Full Membership</option>
                <option value="e">E-membership</option>
              </select>
            </div>
            <div className={styles.input}>
              <label>Membership Duration</label>
              <select name="membershipDuration" value={formData.membershipDuration} onChange={handleChange} required>
                <option value="">Select</option>
                {[...Array(10)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1} Year{(i + 1) > 1 ? 's' : ''}</option>
                ))}
              </select>
            </div>
            <div className={styles.input}>
              <label>Chapter</label>
              <select name="chapter" value={formData.chapter} onChange={handleChange} required>
                <option value="">Select</option>
                {chapters.map((chapter) => (
                  <option key={chapter} value={chapter}>{chapter}</option>
                ))}
              </select>
            </div>
            <div className={styles.input}>
              <label>Leadership Position</label>
              <select name="leadershipPosition" value={formData.leadershipPosition} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            {formData.leadershipPosition === 'yes' && (
              <div className={styles.input}>
                <label>Manchester United Membership ID</label>
                <input type="text" name="manUtdMembershipID" value={formData.manUtdMembershipID} onChange={handleChange} required />
              </div>
            )}
            <div className={styles.joinBtns}>
              <button type="button" onClick={handlePreviousStep}>Previous</button>
              <button type="button" onClick={handleNextStep}>Next</button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className={styles.formSection}>
            <h2>Additional Information</h2>
            <div className={styles.input}>
              <label>How did you hear about us?</label>
              <select name="heardAboutUs" value={formData.heardAboutUs} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="website">Website</option>
                <option value="social_media">Social Media</option>
                <option value="friend">Friend</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className={styles.input}>
              <label>Reason for Joining</label>
              <textarea name="reasonForJoining" value={formData.reasonForJoining} onChange={handleChange} required />
            </div>
            <div className={styles.joinBtns}>
              <button type="button" onClick={handlePreviousStep}>Previous</button>
              <button type="button" onClick={handleNextStep}>Next</button>
            </div>
          </div>
        )}

        {step === 5 && (
          <div className={styles.formSection}>
            <h2>Agreement</h2>
            <div className={styles.input}>
              <label>
                <input type="checkbox" name="agreement" checked={formData.agreement} onChange={handleChange} required />
                I agree to the terms and conditions
              </label>
            </div>
            <div className={styles.input}>
              <label>
                <input type="checkbox" name="privacyPolicy" checked={formData.privacyPolicy} onChange={handleChange} required />
                I agree to the privacy policy
              </label>
            </div>
            <div className={styles.input}>
              <label>Signature</label>
              <input type="text" name="signature" value={formData.signature} onChange={handleChange} required />
            </div>
            <div className={styles.input}>
              <label>Date</label>
              <input type="date" name="date" value={formData.date} onChange={handleChange} required />
            </div>
            <div className={styles.joinBtns}>
              <button type="button" onClick={handlePreviousStep}>Previous</button>
              <button type="submit">Submit</button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default JoinComponent;