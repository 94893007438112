import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import styles from './CheckOutComponent.module.css';

const CheckOutComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { product } = state || {};

  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');

  const publicKey = 'pk_test_201a7fc56153c4c1e064b22f6811400ac80a9c41'; // Replace with your Paystack public key
  const amount = product.price ? product.price * 100 : product.originalPrice * 100; // Amount in pesewas (1 GHS = 100 pesewas)
  
  const handlePaymentSuccess = (reference) => {
    console.log('Payment successful!', reference);
    navigate('/success');
  };

  const handlePaymentClose = () => {
    console.log('Payment closed');
  };

  const componentProps = {
    email,
    amount, // Amount in pesewas
    publicKey,
    text: "Proceed to Payment",
    onSuccess: handlePaymentSuccess,
    onClose: handlePaymentClose,
    currency: 'GHS', // Set currency to GHS (Ghanaian Cedis)
  };

  if (!product) return <div>No product selected</div>;

  return (
    <div className={styles.checkoutContainer}>
      <h2>Checkout</h2>
      <div className={styles.productInfo}>
        <img src={`https://api.mufcredbrigade.com/${product.image}`} alt={product.name} className={styles.productImage} />
        <div>
          <h3>{product.name}</h3>
          {!product.price && <p>GH₵{product.originalPrice}</p>}
          {product.price && <p>GH₵{product.price}</p>}
        </div>
      </div>
      <div className={styles.formSection}>
        <div className={styles.formGroup}>
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter your full name"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="address">Shipping Address</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter your shipping address"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
        </div>
      </div>
      <PaystackButton {...componentProps} />
    </div>
  );
};

export default CheckOutComponent;