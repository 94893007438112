import React from 'react';
import { NavLink} from 'react-router-dom';
import styles from './FoundersComponent.module.css';
import banner from './../../assets/images/Manchester-United.jpg';
import founder from './../../assets/images/Manchester-United.jpg'

const founders = [
  {
    name: "Dr. Boateng Samuel",
    title: "FOUNDER",
    details: "Dr. Boateng Samuel is a Ghanaian medical professional who works at the Southern California hospital in the United States of America. Dr. Boateng has his Basic and Secondary Education in Ghana in the early 2000s. He graduated from Central South University as a medical doctor (MBBS). He is currently studying his PhD in Cardiovascular Medicine at University of California",
    image: founder, // Update with actual image path if available
  }
];

const FoundersComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src={banner} alt="banner" />
      </div>
      <div className={styles.foundersContent}>
        <aside className={styles.sidebar}>
        <ul>
            <li>
              <NavLink to="/about-us" activeClassName={styles.activeLink}>RBSU Timeline</NavLink>
            </li>
            <li>
              <NavLink to="/committee" activeClassName={styles.activeLink}>Executive Committee</NavLink>
            </li>
            <li>
              <NavLink to="/board-executives" activeClassName={styles.activeLink}>The Trust Board</NavLink>
            </li>
            <li>
              <NavLink to="/constitution" activeClassName={styles.activeLink}>Our Constitution</NavLink>
            </li>
            <li>
              <NavLink to="/founders" activeClassName={styles.activeLink}>Founders</NavLink>
            </li>
            <li>
              <NavLink to="/faq" activeClassName={styles.activeLink}>FAQ</NavLink>
            </li>
          </ul>
        </aside>
        <main className={styles.mainContent}>
          <h2>Founders</h2>
          {founders.map((founder, index) => (
            <div key={index} className={styles.founder}>
              <div className={styles.imageWrapper}>
                <img
                  src={founder.image}
                  alt={`${founder.name}`}
                  className={styles.image}
                />
              </div>
              <div className={styles.details}>
                <h3>{founder.name}</h3>
                <p className={styles.title}>{founder.title}</p>
                <p>{founder.details}</p>
                {founder.details.length > 100 && <a href="/#">Read more</a>}
              </div>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
};

export default FoundersComponent;
