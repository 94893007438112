import React from 'react'
import Footer from './../component/Footer/Footer'
import Navbar from './../component/Navbar/Navbar'
import ContactComponent from './../component/ContactComponent/ContactComponent'

const ContactPage = () => {
  return (
    <>
    <Navbar />
    <ContactComponent />
    <Footer />
    </>
  )
}

export default ContactPage