import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import redbrigade from './../../assets/images/redbrigade.png';
import ReactCountryFlag from "react-country-flag";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChaptersOpen, setIsChaptersOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleChaptersDropdown = () => {
    setIsChaptersOpen(!isChaptersOpen);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.logoContainer}>
        <img src={redbrigade} alt="Red Brigade logo" className={styles.logo} />
        <h1 className={styles.brand}>MUFC-Red Brigade</h1>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <nav className={`${styles.navLinks} ${isOpen ? styles.show : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog/news">News</Link></li>
          {/* <li className={`${styles.dropdown} ${isChaptersOpen ? styles.showDropdown : ''}`}>
            <Link to="/chapters" onClick={toggleChaptersDropdown}>Chapters</Link>
            <div className={styles.dropdownContent}>
              <Link to="/chapters/ghana">
                <ReactCountryFlag countryCode="GH" svg className={styles.flagIcon} />
                Ghana
              </Link>
              <Link to="/chapters/nigeria">
                <ReactCountryFlag countryCode="NG" svg className={styles.flagIcon} />
                Nigeria
              </Link>
              <Link to="/chapters/sierra-leone">
                <ReactCountryFlag countryCode="SL" svg className={styles.flagIcon} />
                Sierra Leone
              </Link>
              <Link to="/chapters/zambia">
                <ReactCountryFlag countryCode="ZM" svg className={styles.flagIcon} />
                Zambia
              </Link>
            </div>
          </li> */}
          {/* <li><Link to="/store">Shop</Link></li> */}
          <li className={styles.dropdown}>
            <Link to="/about-us">About</Link>
            <div className={styles.dropdownContent}>
              <Link to="/about-us">About Us</Link>
              <Link to="/committee">Executive Committee</Link>
              <Link to="/board-executives">The Trust Board</Link>
              <Link to="/constitution">Constitution</Link>
              <Link to="/founders">Founders</Link>
              <Link to="/faq">FAQ</Link>
            </div>
          </li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/join-us">Join</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
