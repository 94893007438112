import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import FooterComponent from '../component/Footer/Footer'
import SuccessComponent from './../component/SuccessComponent/SuccessComponent'

const SuccessPage = () => {
  return (
    <>
    <Navbar />
    <SuccessComponent />
    <FooterComponent />
    </>
  )
}

export default SuccessPage